<template>
  <div>
    <v-card class="pa-5">
      <div>
        <h2 class="text-subtitle-1 font-weight-bold mr-4">
          {{ $t('logs.logs') }}
        </h2>
      </div>
      <div class="filters">
        <v-form
          ref="form"
          v-model="valid"
        >
          <div class="w-100 d-flex justify-lg-space-between my-4">
            <div class="filters d-flex">
              <div>
                <label for="user">{{ $t('logs.user') }}</label>
                <v-select
                  v-model="filters.user"
                  id="user"
                  :items="users"
                  item-text="name"
                  item-value="id"
                  outlined
                  required
                  validate-on-blur
                  dense
                  single-line
                  hide-details
                ></v-select>
              </div>
              <div class="mx-2">
                <DatePicker
                  v-model="filters.localFrom"
                  :label="$t('filters.dateFrom')"
                  :max="maxDate"
                  :rules="[dateRange]"
                />
              </div>
              <div class="mx-2">
                <DatePicker
                  v-model="filters.localTo"
                  :label="$t('filters.dateTo')"
                  :max="maxDate"
                  :rules="[dateRange]"
                />
              </div>
              <div class="mt-4">
                <v-btn
                  color="primary"
                  @click="getLogs"
                >
                  {{ $t('logs.filter') }}
                </v-btn>
                <v-btn
                  class="mx-2"
                  @click="clearFilters"
                >
                  {{ $t('logs.clear') }}
                </v-btn>
              </div>
            </div>
            <div class="actions d-flex">
              <v-btn
                color="primary"
                class="mt-4"
                :disabled="!filters.user"
                @click="exportToCSV"
              >
                {{ $t('logs.export') }}
              </v-btn>
            </div>
          </div>
        </v-form>
      </div>
      <div>
        <data-table
          ref="dataTable"
          :headers="headers"
          item-key="dateTime"
          show-expand
          endpoint="/Logs/GetLogs"
          serverPagination
          :additionalParams="params"
        >
          <template v-slot:item.dateTime="{item}">
            {{ item.dateTime | dateFormat('DD.MM.YYYY HH:mm') }}
          </template>
          <template v-slot:expanded-item="{headers, item}">
            <td class="pa-6" :colspan="headers.length">
              <h4>{{ $t('logs.objectData') }}</h4>
              {{ item.objectData }}
            </td>
          </template>
        </data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DatePicker from '@/components/forms/DatePicker'
import services from '@/services'
import moment from 'moment'

export default {
  name: 'Logs',
  components: {
    DatePicker
  },
  data () {
    return {
      params: {
        localTo: null,
        localFrom: null,
        user: null
      },
      filters: {
        localTo: null,
        localFrom: null,
        user: null
      },
      valid: true,
      logs: [],
      dataTableOptions: {
        page: 1,
        pageSize: 100
      },
      totalLogsCount: 0,
      headers: [
        {
          text: this.$t('logs.operationDescription'),
          value: 'operationDescription'
        },
        {
          text: this.$t('logs.userId'),
          value: 'user.id'
        },
        {
          text: this.$t('logs.username'),
          value: 'user.fullName'
        },
        {
          text: this.$t('logs.dateTime'),
          value: 'dateTime'
        },
        {
          text: this.$t('logs.ip'),
          value: 'ip'
        }
      ],
      loading: false,
      maxDate: moment().format('YYYY-MM-DD'),
      dateRange: () => (this.filters.localFrom && this.filters.localFrom.length > 0 && this.filters.localTo && this.filters.localTo.length > 0) ? moment(this.filters.localFrom).unix() > moment(this.filters.localTo).unix() ? this.$t('validation.dateRange') : true : true
    }
  },
  computed: {
    ...mapGetters({
      users: 'users/users'
    })
  },
  created () {
    this.initFilters()
  },
  methods: {
    async getLogs () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.params = {
        user: this.filters.user,
        localFrom: moment(this.filters.localFrom).startOf('day').format('YYYY-MM-DD HH:mm'),
        localTo: moment(this.filters.localTo).endOf('day').format('YYYY-MM-DD HH:mm')
      }
    },
    initFilters () {
      const now = new Date()
      const from = new Date()
      from.setDate(now.getDate() - 7)
      this.filters = {
        localFrom: moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'),
        localTo: moment().endOf('day').format('YYYY-MM-DD'),
        user: null
      }
      this.params = this.filters
    },
    clearFilters () {
      const now = new Date()
      const from = new Date()
      from.setDate(now.getDate() - 7)
      this.filters = {
        localFrom: moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm'),
        localTo: moment().endOf('day').format('YYYY-MM-DD HH:mm'),
        user: null
      }
      this.params = this.filters
    },
    async exportToCSV () {
      await services.post('Logs/ExportToCSV', {
        userId: this.filters.user,
        from: moment(this.filters.localFrom).startOf('day'),
        to: moment(this.filters.localTo).endOf('day')
      })
    }
  }
}
</script>
